import $api from "../api";
// import axios from "axios";
export const actions = {
    getListJSON({ rootGetters, dispatch }) {
        return new Promise(function (resolve) {
            $api
                .get(`${window.location.origin}/json/notification.json`, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    console.log(res)
                    if (res.status == 200) {
                        resolve(res.data);
                    }
                })
                .catch(function (err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => { });
                    }
                });
        });
    },
    getList({ rootGetters, dispatch },pl) {
        return new Promise(function (resolve) {
            $api
                .get(`/notifications?`+ new URLSearchParams(pl).toString(), {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve(res.data.data);
                    }
                })
                .catch(function (err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => { });
                    }
                });
        });
    },
    readNotification({ rootGetters, dispatch },pl) {
        return new Promise(function (resolve) {
            $api
                .post(`/notification/${pl.id}`,pl, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve(res.data);
                    }
                })
                .catch(function (err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => { });
                    }
                });
        });
    },
};
export default {
    namespaced: true,
    actions
};