import $api from "../api";
import Swal from 'sweetalert2';
// import axios from "axios";
export const actions = {
    getPosts({ rootGetters, dispatch },pl) {
        return new Promise(function (resolve) {
            $api
                .get(`posts?`+ new URLSearchParams(pl).toString(), {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve(res.data.data);
                    }
                })
                .catch(function (err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => { });
                    }
                });
        });
    },
    createPosts({ rootGetters },pl) {
        return new Promise(function (resolve) {
            $api
                .post(`post`,pl,{
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve(res.data);
                    }
                })
                .catch(function (err) {
                    // if (err.response.status == 401) {
                    //     dispatch("auth/logoutUser", {}, { root: true }).then(() => { });
                    // }
                    // if(err.response.status == 422){
                    //     resolve(err.response.data.message);
                    // }
                    // Swal.fire({
                    //     html: err.response.data.message,
                    //     type: "error",
                    //     icon: "error",
                    //     padding: "2em",
                    // });
                    if(err.response.status == 422){
                        resolve(err.response.data.message)
                    }else{
                        Swal.fire({
                            html: 'Content too large',
                            type: "error",
                            icon: "error",
                            padding: "2em",
                        });
                    }
                });
        });
    },
    showPost({ rootGetters },pl) {
        return new Promise(function (resolve) {
            $api
                .get(`post/${pl.id}`,{
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve(res.data.data);
                    }
                })
                .catch(function (err) {
                    if(err.response.status == 422){
                        resolve(err.response.data.message)
                    }else{
                        Swal.fire({
                            html: 'Content too large',
                            type: "error",
                            icon: "error",
                            padding: "2em",
                        });
                    }
                });
        });
    },
    postEdit({ rootGetters },pl) {
        return new Promise(function (resolve) {
            $api
                .post(`post/${pl.id}`,pl.formdata,{
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve(res.data);
                    }
                })
                .catch(function (err) {
                    // if (err.response.status == 401) {
                    //     dispatch("auth/logoutUser", {}, { root: true }).then(() => { });
                    // }
                    // if(err.response.status == 422){
                    //     resolve(err.response.data.message);
                    // }
                    Swal.fire({
                        html: err.response.data.message,
                        type: "error",
                        icon: "error",
                        padding: "2em",
                    });
                });
        });
    },
    postRemove({ rootGetters },pl) {
        return new Promise(function (resolve) {
            $api
                .delete(`post/${pl.id}`,{
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve(res.data);
                    }
                })
                .catch(function (err) {
                    Swal.fire({
                        html: err.response.data.message,
                        type: "error",
                        icon: "error",
                        padding: "2em",
                    });
                });
        });
    },
    postReaction({ rootGetters, dispatch },pl) {
        return new Promise(function (resolve) {
            $api
                .post(`reaction `,pl,{
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve(res.data);
                    }
                })
                .catch(function (err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => { });
                    }
                    if(err.response.status == 422){
                        resolve(err.response.data.message);
                    }
                });
        });
    },
    updateAdmin({ rootGetters, dispatch },pl) {
        return new Promise(function (resolve) {
            $api
                .post(`admin/${pl.id}`,pl,{
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve(res.data);
                    }
                })
                .catch(function (err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => { });
                    }
                    if(err.response.status == 422){
                        resolve(err.response.data.message);
                    }
                });
        });
    },
    deleteAdmin({ rootGetters, dispatch },pl) {
        return new Promise(function (resolve) {
            $api
                .delete(`admin/${pl.id}`,{
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve(res.data);
                    }
                })
                .catch(function (err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => { });
                    }
                    if(err.response.status == 422){
                        resolve(err.response.data.message);
                    }
                });
        });
    },
};
export default {
    namespaced: true,
    actions
};