import axios from "axios";
export const state = {
    data: {
        list: [],
        links: [],
        current_page: 0,
        last_page: 0,
        per_page: 0,
        total: 0,
    },
};

export const actions = {
    getList({ rootGetters, dispatch }) {
        return new Promise(function(resolve) {
            axios
                .get(`${window.location.origin}/json/posts.json`, {
                        headers: {
                            Authorization: rootGetters["auth/bearer_token"],
                        },
                    }
                )
                .then(function(res) {
                    if (res.status == 200) {
                        resolve(res.data);
                    }
                })
                .catch(function(err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => {});
                    }
                });
        });
    },
};

export const mutations = {
    setData(state, data) {
        state.data.list = data.data;
        state.data.links = data.links;
        state.data.current_page = data.current_page;
        state.data.last_page = data.last_page;
        state.data.per_page = data.per_page;
        state.data.total = data.total;
    },
};

export const getters = {};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};