import $api from "../api";
// import axios from "axios";
import Swal from 'sweetalert2';
export const actions = {
    getListJSON({ rootGetters, dispatch }) {
        return new Promise(function (resolve) {
            $api
                .get(`${window.location.origin}/json/chat.json`, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve(res.data);
                    }
                })
                .catch(function (err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => { });
                    }
                });
        });
    },
    getList({ rootGetters, dispatch }) {
        return new Promise(function (resolve) {
            $api
                .get(`threads`, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve(res.data.data);
                    }
                })
                .catch(function (err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => { });
                    }
                });
        });
    },
    newThread({ rootGetters },pl) {
        return new Promise(function (resolve) {
            $api
                .post(`thread`,pl, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve(res.data);
                    }
                })
                .catch(function (err) {
                    if (err) {
                        resolve(err.response.data.message);
                    }
                });
        });
    },
    updateThread({ rootGetters },pl) {
        return new Promise(function (resolve) {
            $api
                .post(`thread/update/${pl.id}`,pl, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve(res.data);
                    }
                })
                .catch(function (err) {
                    if (err) {
                        resolve(err.response.data.message);
                    }
                });
        });
    },
    deleteThread({ rootGetters },pl) {
        return new Promise(function (resolve) {
            $api
                .delete(`thread/${pl.id}`, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve(res.data);
                    }
                })
                .catch(function (err) {
                    if (err) {
                        resolve(err.response.data.message);
                    }
                });
        });
    },
    message({ rootGetters },pl) {
        return new Promise(function (resolve) {
            $api
                .post(`message`,pl, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve(res.data);
                    }
                })
                .catch(function (err) {
                    if(err.response.status == 422){
                        resolve(err.response.data.message);
                    }
                    if(err.response.status == 413){
                        Swal.fire({
                            html: 'Content too large',
                            type: "error",
                            icon: "error",
                            padding: "2em",
                        });
                    }else{
                        Swal.fire({
                            html: err,
                            type: "error",
                            icon: "error",
                            padding: "2em",
                        });
                    }
                });
        });
    },
    showThread({ rootGetters, dispatch },pl) {
        return new Promise(function (resolve) {
            $api
                .get(`thread/${pl.id}?` + new URLSearchParams(pl).toString(), {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve(res.data.data);
                    }
                })
                .catch(function (err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => { });
                    }
                });
        });
    },
    checkThread({ rootGetters },pl) {
        return new Promise(function (resolve) {
            $api
                .post(`thread/check-thread`,pl,{
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    console.log(res)
                    if (res.status == 200) {
                        resolve(res.data);
                    }
                })
                .catch(function (err) {
                    if (err) {
                        resolve(err);
                    }
                });
        });
    },
};
export default {
    namespaced: true,
    actions
};