<template>
	<div>
		<span class="new-register  bg-white p-1">Newly Registered:</span>
		<marquee class="mq fw-bold" behavior="" direction="" >
			<span style="margin-right: 50px;margin-left: 20px;" v-for="data,index in this.new_data" :key="index">{{`${data.first_name}`}}</span>
		</marquee>
		<div class="footer-nav" v-if="
			this.$route.path == '/' ||
			this.$route.path == '/messages' ||
			this.$route.path == '/notifications' ||
			this.$route.path == '/notifications-view' ||
			this.$route.path == '/profile'
		
		">
			<div class="p-2 px-4 d-flex">
				<div class="px-4" :class="this.$route.path == '/' ? 'active-nav': ''">
					<div class="nav-items text-center" @click="gotoHome()">
						<i class="bx bxs-home fs-1"></i>
						<label>Home</label>
					</div>
				</div>
				<div class="px-4" :class="this.$route.path == '/messages' ? 'active-nav': ''">
					<div class="nav-items text-center" @click="gotoMessages()">
						<h5 class="badge bg-danger badge-style" v-if="message_count >= 1">{{message_count}}</h5>
						<i class="bx bxs-message fs-1"></i>
						<label>Messages</label>
					</div>
				</div>
				<div class="px-4" :class="this.$route.path == '/notifications' ? 'active-nav': ''">
					<div class="nav-items text-center" @click="gotoNotifications()">
						<h5 class="badge bg-danger badge-style">{{notification_count}}</h5>
						<i class="bx bxs-bell fs-1"></i>
						<label>Notifications</label>
					</div>
				</div>
				<div class="">
					<div class="nav-items text-center">
						<b-dropdown
							right
							variant="black"
							toggle-class="header-item"
							menu-class="dropdown-menu-end"
						>
							<template v-slot:button-content>
								<img v-if="!this.profile.image" 
									height="40" width="40"
									class="raidus-100 footer-profile"
									src="@/assets/images/profiles/default.png" >
								<img
									v-if="this.profile.image"
									class="rounded-circle footer-profile"
									:src="`${image_api}/${this.profile.image}`"
									height="40"
									width="40"
									alt="Header Avatar"
								/>
								<i
									class="mdi mdi-chevron-down d-none d-xl-inline-block "
								></i>
							</template>
							<!-- item-->
							<button @click="gotoProfile()" class="dropdown-item text-dark">
								<i
									class="bx bx-user font-size-16 align-middle me-1"
								></i>
								{{ $t("navbar.dropdown.henry.list.profile") }}
							</button>
							<button @click="logout()" class="dropdown-item text-danger">
								<i
									class="bx bx-power-off font-size-16 align-middle me-1 text-danger"
								></i>
								{{ $t("navbar.dropdown.henry.list.logout") }}
							</button>
						</b-dropdown>
					</div>
				</div>
			</div>
		</div>
	</div>
	<router-view></router-view>
	<div>
		<!-- MODALS -->
		<b-modal 
			v-model="change_password_modal"
			no-close-on-backdrop
			hide-footer
			size="md"
			title="CHANGE PASSWORD" 
			>
			<div class="row">
				<div class="col-12">
					<div class="mb-3">
						<label>CURRENT PASSWORD</label>
						<input class="form-control mb-3" type="password" v-model="pl.current_password">
						<label>NEW PASSWORD</label>
						<input class="form-control" type="password" v-model="pl.new_password">
					</div>
				</div>
			</div>
			<div class="text-end mt-3">
				<b-button class="mx-1" variant="secondary" @click="change_password_modal = false">Close</b-button>
				<b-button class="mx-1" variant="info" @click="changePW()">SUBMIT</b-button>
			</div>
		</b-modal>
	</div>
</template>

<script>
import { mapActions } from "vuex";
import Swal from 'sweetalert2';
import { mapGetters } from "vuex";
import $cookies from "vue-cookies";
// import Echo from "laravel-echo";
export default {
	name: "App",
	data(){
		return{
			change_password_modal: false,
			notification_count: null,
			message_count: null,
			data: {
				list: [],
                reactions:[],
                media:[],
				links: [],
				current_page: 1,
				per_page: 1,
				last_page: 1,
			},
			data_messages: [],
			pl:{
				current_password: '',
				new_password: '',
			},
			first_name:[
				"Patricia",
				"Linda",
				"Barbara",
				"Elizabeth",
				"Jennifer",
				"Maria",
				"Susan",
				"Margaret",
				"Dorothy",
				"Lisa",
				"Nancy",
				"Karen",
				"Betty",
				"Helen",
				"Sandra",
				"Donna",
				"Carol",
				"Ruth",
				"Sharon",
				"Michele",
				"Laura",
				"Sarah",
				"Kimberly",
				"Deborah",
				"Jessica",
				"Shirley",
				"Cynthia",
				"Angela",
				"Melissa",
				"Mary",
				"Brenda",
				"Amy",
				"Anna",
				"Rebecca",
				"Virginia",
				"Kathleen",
				"Pamela",
				"Martha",
				"Debra",
				"Amanda",
				"Stephanie",
				"Carolyn",
				"Christine",
				"Marie",
				"Janet",
				"Catherine",
				"Frances",
				"Ann",
				"Joyce",
				"Diane",
				"Alice",
				"Julie",
				"Heather",
				"Teresa",
				"Doris",
				"Gloria",
				"Evelyn",
				"Jean",
				"Cheryl",
				"Mildred",
				"Katherine",
				"Joan",
				"Ashley",
				"Judith",
				"Ose",
				"Janice",
				"Kelly",
				"Nicole",
				"Judy",
				"Christina",
				"Kathy",
				"Theresa",
				"Beverly",
				"Denice",
				"Tammy",
				"Irene",
				"Jane",
				"Lori",
				"Rachel",
				"Marilyn",
				"Andrea",
				"Kathryn",
				"Louise",
				"Sara",
				"Anne",
				"Jacqueline",
				"Wanda",
				"Bonnie",
				"Julia",
				"Ruby",
				"Lois",
				"Tina",
				"Phyllis",
				"Norma",
				"Paula",
				"Diana",
				"Annie",
				"Lillian",
				"Emily",
				"Obin",
				"Peggy",
				"Crystal",
				"Gladys",
				"Rita",
				"Dawn",
				"Connie",
				"Florence",
				"Tracy",
				"Edna",
				"Tiffany",
				"Carmen",
				"Rosa",
				"Cindy",
				"Grace",
				"Wendy",
				"Victoria",
				"Edith",
				"Kim",
				"Sherry",
				"Sylvia",
				"Josephine",
				"Megan",
				"Thelma",
				"Alicia",
				"Shannon",
				"Suzanne",
				"Sheila",
				"Michele",
				"Ethel",
				"Gail",
				"Ellen",
				"Bertha",
				"Elaine",
				"Darlene",
				"Marjorie",
				"Veronica",
				"Carrie",
				"Jill",
				"Charlotte",
				"Erin",
				"Monica",
				"Geraldine",
				"Esther",
				"Lauren",
				"Pauline",
				"Cathy",
				"Emma",
				"Joann",
				"Juanita",
				"Lorraine",
				"Anita",
				"Lynn",
				"Rhonda",
				"Sally",
				"Hazel",
				"Regina",
				"Amber",
				"Erica",
				"Eva",
				"Beatrice",
				"Debbie",
				"Dolores",
				"April",
				"Bernice",
				"Leslie",
				"Audrey",
				"Clara",
				"Yvonne",
				"Lucille",
				"Annette",
				"Jamie",
				"June",
				"Joanne",
				"Samantha",
				"Eleanor",
				"Marion",
				"Valerie",
				"Dana",
				"Danielle",
				"Stacy",
			],
			new_data:[],
			image_api: process.env.VUE_APP_BACKEND_API_IMAGES
		}
	},
	components: {},
	computed: {
        ...mapGetters('auth', ['profile']),
	},
	methods: {
		...mapActions("auth", {
			getAuthProfile: "getProfile",
		}),
		...mapActions("messages", {
			getThreads: "getList",
		}),
		...mapActions("staticDatas", {
			getMarquee: "getListJSON",
		}),
        ...mapActions('changePassword', {
            changePass: 'changePassword',
        }),
		...mapActions("userNotification", {
			// notificationList: "getListJSON",
			notificationList: "getList",
			readPost: "readNotification",
		}),
        async initList(p) {
			var pl = {
				page: p,
                // owner: this.profile.id
                user_id: this.profile.id
			};
			const data = await this.notificationList(pl);
			this.data.list = data.data;
			this.data.links = data.links;
			this.data.current_page = data.current_page;
			this.data.per_page = data.per_page;
			this.data.last_page = data.last_page;
			this.data.list.forEach(element => {
				if(element.status == 'unread'){
					this.notification_count ++;
				}
			});
		},
        async initMessageList() {
			const data = await this.getThreads();
			this.data_messages.list = data.data;
            this.data_messages.list.forEach(element => {
                if(element.status == 'unread'){
                    this.message_count ++;
                }
            });
		},
		async profileInterval() {
			await this.getAuthProfile();
		},
		startInterval() {
			this.intervalId = setInterval(this.profileInterval, 10000);
		},
        async changePW(){
            var pl = {
				current_password: this.pl.current_password,
				new_password: this.pl.new_password
            }
            const confirmed = await Swal.fire({
                title: 'Are you sure you want to change password?',
                html: ``,
                icon: 'warning',
                showCancelButton: true,
                cancelButtonColor: '#74788d',
            }).then((result) => {
                return result.value;
            });
            if (confirmed) {
                const res = await this.changePass(pl);
                if (res.status == 200 || res.status == 'success') {
                    Swal.fire({
                        title: "Success",
                        html: `Password successfuly changed`,
                        type: "success",
                        icon: "success",
                        padding: "2em",
                    });
					this.profileInterval();
					this.gotoLogin();
                    this.create_modal = false;
                }else{
                    Swal.fire({
                        html: res,
                        type: "error",
                        icon: "error",
                        padding: "2em",
                    });
                }
            }
        },
		randomizer(fname){
			for (let index = 0; index < 3; index++) {
				fname = this.first_name[Math.floor(Math.random() * this.first_name.length)]
				var pl= {
					first_name: fname,
				}
				this.new_data.push(pl)
			}
		},
		logout() {
			$cookies.remove("token");
			window.location.reload();
		},
		gotoProfile() {
			this.$router.push({path: "/profile"});
		},
		gotoHome(){
			// this.$router.push({path: "/"});
			window.location.href = '/'
		},
		gotoMessages(){
			this.$router.push({path: "/messages"});
		},
		gotoNotifications(){
			this.$router.push({path: "/notifications"});
		},
        // webSocket() {
		// 	window.Pusher = require("pusher-js");
		// 	window.Echo = new Echo({
		// 		broadcaster: "pusher",
		// 		key: process.env.VUE_APP_PUSHER_APP_KEY,
		// 		// wsHost: process.env.VUE_APP_PUSHER_HOST,
		// 		wsPort: process.env.VUE_APP_PUSHER_PORT,
		// 		disableStats: true,
		// 		cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
		// 		enabledTransports: ["ws", "wss"],
		// 	});
		// 	window.Echo.channel(`notification-list`).listen(
		// 		"NewNotificationEvent",
		// 		async (e) => {
		// 			console.log(e)
        //             if(e.type == 'like' || e.type == 'haha' || e.type == 'heart' || e.type == 'wow'){
        //                 var pl_like = {
        //                     reactions: e.reactions,
        //                     text: e.text,
        //                     media: e.media,
        //                     owner_id: e.owner_id,
        //                     owner: e.owner,
        //                     created_at: e.created_at,
        //                     comments: []
        //                 };
        //                 this.post_modal = false;
        //                 await this.notification_count ;
        //             }
		// 		}
		// 	);
		// },
	},
    mounted(){
		if(this.$route.path != '/login'){
			this.startInterval();
			this.profileInterval();
			setTimeout(() => {
				this.initList(1);
				this.initMessageList(1);
			}, 1000);
		}
        // this.startInterval();
		// this.profileInterval();
		if(this.profile.new_user == 1){
			this.change_password_modal = true;
		}
		this.randomizer();
    },
};
</script>
<style>
.mq{
	background: #000000;
	position: fixed;
	bottom: 0;
	z-index: 999;
	height: 30px;
	color: white;
	align-content: center;
	text-transform: uppercase;
	font-family: sans-serif;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}
.new-register{
    position: fixed;
	height: 30px;
    bottom: 0;
    left: 0;
	font-weight: bolder;
	color: rgb(40, 112, 206);
	z-index: 1000;
}
.footer-nav{
	display: none;
}
.nav-items{
	display: flex;
	flex-direction: column;
	padding-top: 5px;
	position: relative;
}
.footer-nav > div{
	display: flex;
	justify-content: center;
}
.active-nav{
	color: #0866FF;
	/* border-top: 2px solid #0866FF; */
}
.footer-profile{
	margin-top: -35px;
}
.badge-style{
	width: auto;
	position: absolute;
	right: 20px;
	top: -2px;
}
@media(max-width:500px){
	.footer-nav{
		display: block;
		background: #ffffff;
		position: fixed;
		bottom: 30px;
		height: 60px;
		width: 100%;
		z-index: 1000;
	}
}
@media(max-width:375px){
	.footer-nav > div > div{
		padding-left: 14px !important;
		padding-right: 14px !important;
	}
	.footer-profile{
		margin-right: -30px !important;
	}
}
@media(max-width:320px){
	.footer-nav > div > div{
		padding-left: 10px !important;
		padding-right: 10px !important;
		margin-right: -5px !important;
	}
	.footer-profile{
		margin-right: -10px !important;
	}
}
</style>